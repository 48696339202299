
import { defineComponent, onMounted } from "vue";
import Chart from "@/components/1crm/1sekolah/dashboard/Widget11.vue";

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";

export default defineComponent({
  name: "widgets-mixed",
  components: {
    Chart,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Dashboard", ["Dashboard"]);

      MenuComponent.reinitialization();
    });
  },
});
